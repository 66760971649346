<template>
  <div class="noscrollmain">
    <common-top
      title="活跃用户排名"
      :isShowSearch="isShowSearch"
      @search="showModal = true"
    ></common-top>

    <dept-choose-com
      :defaultEndDate="endDate"
      :defaultStartDate="startDate"
      :showModal="showModal"
      @chooseDept="chooseDept"
      :isShowDept="isShowDept"
      @cancel="showModal = false"
      :isShowTime="true"
    ></dept-choose-com>

    <task-load-com
      :postData="postData"
      :postUrl="postUrl"
      @initData="initData"
      :showTaskLoad="showTaskLoad"
      v-if="showTaskLoad"
    ></task-load-com>
    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>
    <loading-cmp v-if="showLoad"></loading-cmp>

    <div v-show="showList && isUserPm">
      <desc-com
        :title="descTitle"
        :startDate="startDate"
        :endDate="endDate"
      ></desc-com>

      <div
        style="
          margin-top: -4px;
          text-align: left;
          color: #999;
          padding: 0 10px;
          font-size: 10px;
        "
      >
        活跃度 = 阅读+点赞+评论+分享
      </div>

      <div style="width: 100%; margin-top: 20px"></div>

      <div style="margin-top: 30px">
        <list-com
          :columns="columns"
          :title="title"
          :showList="dataList"
        ></list-com>
        <load-more
          @refresh="onRefresh"
          :load-status="isLoadMore"
          :isShow="loadMoreShow"
        ></load-more>
      </div>
    </div>

    <no-pass-com v-show="!isUserPm"> </no-pass-com>
  </div>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";
import moment from "moment";
import { wxConPost, getUserPm, statsPost } from "../../api/httpApi";
import commonTop from "../../components/commonTop.vue";
import pieChart from "../../components/pieChart.vue";
import loadingCmp from "../../components/loadingCmp.vue";
import diaLogliu from "../../components/dialogLiu.vue";
import listCom from "../../components/listCom.vue";
import deptChooseCom from "../../components/deptLevelChooseCom.vue";
import descCom from "../../components/descCom.vue";
import noPassCom from "../../components/noPassCom.vue";
import loadMore from "../../components/loadMoreData.vue";
import taskLoadCom from "../../components/taskLoadCom.vue";

export default {
  name: "deptJifen",
  components: {
    pieChart,
    loadingCmp,
    commonTop,
    diaLogliu,
    listCom,
    deptChooseCom,
    descCom,
    noPassCom,
    loadMore,
    taskLoadCom,
  },

  data() {
    return {
      postData: {},
      postUrl: "/deptTj/userActivity",
      showTaskLoad: false,

      loadMoreShow: false,
      isLoadMore: true,

      descTitle: "", // 描述说明
      isShowSearch: false, // 是否显示搜索框
      isShowDept: false, // 超管部门选择
      isUserPm: true, // 是否有权限

      showModal: false, // 显示部门弹框
      deptIdArr: [], // 选择部门
      deptLevel: 2, // 部门层级
      startDate: "2022-01-01",
      endDate: "2022-02-20",

      showDetailStatus: false,
      detailInfo: {}, // 单位详情
      title: "单位",
      columns: ["阅读活跃", "点赞活跃", "评论活跃", "分享活跃", "总活跃度"],

      showList: true,
      dataList: [],

      showLoad: false,
      deptType: "单位",

      deptInfo: {},

      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项

      dataTimer: null,

      limit: 100, // 每页显示条数
    };
  },
  methods: {
    // 数据任务组件
    initData(data) {
      this.showTaskLoad = false; // 销毁组件，为下次再次查询

      if (!_.isUndefined(data)) {
        const deptData = data.ret ? data.ret : [];
        this.dealData(deptData);
        // 增加环比同比
      } else {
        this.dealData([]);
      }
    },
    // 数据任务组件初始化
    initTaskCom() {
      let _query = {
        startDate: this.startDate,
        endDate: this.endDate,
        limit: this.limit,
        skip: this.dataList.length,
      };
      if (this.deptIdArr.length > 0) {
        _query["deptIdArr"] = this.deptIdArr;
        _query["tjLevel"] = this.deptLevel;
      }
      this.postData = _query;

      this.showTaskLoad = true;
    },

    // 选择部门
    chooseDept(emitData) {
      this.tjLevel = emitData.level;
      this.deptIdArr = emitData.deptIdArr;
      this.startDate = emitData.startDate;
      this.endDate = emitData.endDate;

      this.showModal = false;
      this.dataList = []; //

      this.initTaskCom();
    },

    // loadMore
    async onRefresh() {
      try {
        this.showLoad = true;
        let _query = {
          startDate: this.startDate,
          endDate: this.endDate,
          limit: this.limit,
          skip: this.dataList.length,
        };

        if (this.deptIdArr.length > 0) {
          _query["deptIdArr"] = this.deptIdArr;
          _query["tjLevel"] = this.deptLevel;
        }
        const ret = await statsPost(this.postUrl, _query);

        let deptData = _.get(ret, ["ret"], []);

        if (deptData.length == 0) {
          this.showLoad = false;
          this.loadMoreShow = true;
          this.isLoadMore = false;
          return;
        }

        const _retData = [];

        for (const iterator of deptData) {
          let allNum =
            iterator["comment"] +
            iterator["count"] +
            iterator["like"] +
            iterator["share"];
          _retData.push({
            name: iterator.name,
            总活跃度: allNum,
            阅读活跃: iterator.count,
            点赞活跃: iterator.like,
            评论活跃: iterator.comment,
            分享活跃: iterator.share,
          });
        }
        this.dataList = this.dataList.concat(_retData);

        this.showLoad = false;

        // 显示加载
        this.isLoadMore = true;
        this.loadMoreShow = true;
      } catch (error) {
        console.log("----", error);
      }
    },
    dealData(dataList) {
      const _retData = [];
      for (const iterator of dataList) {
        let allNum =
          iterator["comment"] +
          iterator["count"] +
          iterator["like"] +
          iterator["share"];
        _retData.push({
          name: iterator.name,
          总活跃度: allNum,
          阅读活跃: iterator.count,
          点赞活跃: iterator.like,
          评论活跃: iterator.comment,
          分享活跃: iterator.share,

          // comment: 0
          // count: 132

          // like: 53
          // name: "王财"
          // share: 36
        });
      }
      this.dataList = _retData;
      this.showLoad = false;
    },

    clearTimmerFun() {
      if (this.dataTimer) {
        clearTimeout(this.dataTimer);
      }
    },
  },
  destroyed() {
    this.clearTimmerFun();
  },
  async created() {
  
    this.startDate = moment().subtract(1, "weeks").format("YYYY-MM-DD");
    this.endDate = moment().subtract(1, "days").format("YYYY-MM-DD");

    const userPm = getUserPm();
    if (userPm && userPm.statsPmStatus) {
      this.tjLevel = userPm.datalevel;
      this.deptIdArr = JSON.parse(localStorage.getItem("pmDeptIdArr"));

      this.isShowSearch = true;
      this.isShowDept = true;

      this.initTaskCom();
    } else {
      this.isUserPm = false;
    }
  },
};
</script>

<style scoped>
.deptMainTop {
  text-align: center;
  /* color: white; */
  padding: 15px 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  grid-template-rows: repeat(1, 1fr);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.deptMainTop .box-item {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  font-size: 11px;
  position: relative;
  font-weight: bold;
}
.deptMainTop .box-item span {
  flex: 1;
  color: #b57464;
  font-size: 16px;
}

.deptMainTop .box-item .numColr,
.numColor {
  font-size: 16px;
  font-weight: bold;
  color: #866060;
}
.deptMainTop .box-item .descColor,
.descColor {
  color: #bbb;
  overflow: hidden;
  font-size: 10px;
}
</style>
